import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration from 'dayjs/plugin/duration';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isToday from 'dayjs/plugin/isToday';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/pt-br';

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {
    dayjs.extend(timezone);
    dayjs.extend(utc);
    dayjs.extend(relativeTime);
    dayjs.extend(duration);
    dayjs.locale('pt-br');
    dayjs.extend(customParseFormat);
    dayjs.extend(isSameOrAfter);
    dayjs.extend(isToday);
    dayjs.extend(LocalizedFormat);
  }


  sortByDateDescending(objArray: any[], paramName: string): any[] {
    const objectsWithDate: any[] = [];
    const objectsWithoutDate: any[] = [];

    for (const obj of objArray) {
      if (!!obj[paramName] && obj[paramName].trim() === '') {
        objectsWithoutDate.push(obj);
      } else {
        objectsWithDate.push(obj);
      }
    }

    objectsWithDate.sort((a, b) => {
      const dateA = new Date(a[paramName]);
      const dateB = new Date(b[paramName]);
      return dateB.getTime() - dateA.getTime();
    });

    return [...objectsWithDate, ...objectsWithoutDate];
  }

  getFirstDayOfMonth(monthsToAdd = 0) {
    return dayjs().subtract(monthsToAdd, 'month').startOf('month').format('YYYY-MM-DD');
  }

  public isDateValid(date: string, format = 'YYYY-MM-DD'): boolean {
    return dayjs(date, format).isValid();
  }

  public manipulateDate(qtd, startOf?, date?) {
    const format = 'YYYY-MM-DDTHH:mm:ss';
    if (startOf) {
      if (date) {
        return dayjs(date, 'YYYY-MM-DD')
          .startOf('day')
          .add(qtd, 'day')
          .format(format);
      }
      return dayjs().startOf('day').add(qtd, 'day').format(format);
    }
    if (date) {
      return dayjs(date, 'YYYY-MM-DD')
        .endOf('day')
        .add(qtd, 'day')
        .format(format);
    }
    return dayjs().endOf('day').add(qtd, 'day').format(format);
  }

  format(date, format?, toDb?, startOf?, endOf?) {
    if (toDb) {
      let dayjsInstance: any = dayjs(date, format);
      if (startOf) {
        dayjsInstance = dayjsInstance.startOf('day');
      }
      if (endOf) {
        dayjsInstance = dayjsInstance.endOf('day');
      }
      return dayjsInstance.format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }
    return dayjs(date).format(format);
  }

  formatPipe(date, format, toDb = false) {
    if (toDb) {
      if (!format) {
        format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
      }
      return dayjs(date, 'DD/MM/YYYY').format(format);
    }
    return dayjs(date).format(format);
  }

  getLocalDateTime(format?) {
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSS';
    }
    return dayjs().format(format);
  }

  getUtcDateTime(format?) {
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }
    return dayjs.utc().format(format);
  }

  setUtcDateTime(date, time, format?) {
    time = time.replace(/:/g, '');
    if (!format) {
      format = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
    }
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date.setHours(time.substring(0, 2));
    date.setMinutes(time.substring(2, 4));
    date.setSeconds(time.substring(4, 6));
    console.log(
      time,
      `${ time.substring(0, 2) }:${ time.substring(2, 4) }:${ time.substring(4, 6) }`,
      `${ date.getHours() }:${ date.getMinutes() }:${ date.getSeconds() }`,
    );
    return dayjs(date).utc().format(format);
  }

  getDateFormatted(date, format, formatExpected) {
    if (date) {
      return dayjs(date, format).format(formatExpected);
    }
    return '';
  }

  getDayOfWeek(day) {
    return dayjs().day(day);
  }

  formatDateTimeToLocal(date, format, timeZoneId) {
    return dayjs.tz(dayjs(date), timeZoneId).format(format);
  }

  lifeTime(date, timeZoneId) {
    return dayjs(
      this.formatDateTimeToLocal(date, 'YYYY-MM-DDTHH:mm:ss.SSS', timeZoneId),
    )
      .startOf('s')
      .fromNow(true);
  }

  diffByYears(date) {
    return dayjs().diff(date, 'years').toString();
  }

  diff(date, unitOfTime) {
    return dayjs().diff(date, unitOfTime);
  }

  millisecondsToDays(milliseconds) {
    // tslint:disable-next-line:no-shadowed-variable
    const duration = dayjs.duration(milliseconds, 'milliseconds');
    return duration.asDays();
  }

  addDays(date, days, format?) {
    if (format) {
      return dayjs(date).add(days, 'days').format(format);
    }
    return dayjs(date).add(days, 'days');
  }

  /**
   * Add unit from a specific date
   * @param date a date string (or now)
   * @param amount amount by unit
   * @param unit a days, months, years...
   * @param format the expected format (default is 'YYYY-MM-DDTHH:mm:ss.SSS')
   */
  intervalPeriodAdd(
    date: dayjs.Dayjs | string = this.getToday(),
    amount: number,
    unit: dayjs.ManipulateType,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS',
  ): string {
    return dayjs(date).add(amount, unit).format(format);
  }

  /**
   * Subtract unit from a specific date
   * @param date a dayjs date or string (or now)
   * @param amount amount by unit
   * @param unit a days, months, years...
   * @param startOf init of unit selected
   * @param format the expected format (default is 'YYYY-MM-DDTHH:mm:ss.SSS')
   */
  intervalPeriodSub(
    date: dayjs.Dayjs | string = this.getToday(),
    amount: number,
    unit: dayjs.ManipulateType,
    startOf = false,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS',
  ): string {
    if (startOf) {
      return dayjs(date).subtract(amount, unit).startOf('day').format(format);
    }
    return dayjs(date).subtract(amount, unit).format(format);
  }

  /**
   * Subtract days from a specific date
   * @param dateFrom a date string (or now)
   * @param days days ammount
   * @param format the expected format (default is 'YYYY-MM-DDTHH:mm:ss.SSS')
   */
  subtractDays(dateFrom, days, format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
    return dayjs(dateFrom).subtract(days, 'd').format(format);
  }

  /**
   * Substract a specific amount of days, weeks, months, years etc. from a date
   * @param dateFrom a date string (or now)
   * @param unit days, weeks, months, years, etc.
   * @param amount quantity to subtract
   * @param format the expected format (default is 'YYYY-MM-DDTHH:mm:ss.SSS')
   */
  subtractByUnit(dateFrom, amount, unit, format = 'YYYY-MM-DDTHH:mm:ss.SSS') {
    return dayjs(dateFrom).subtract(amount, unit).format(format);
  }

  getToday(date?, format?) {
    return dayjs(date, format);
  }

  /**
   * Calculate the difference in DAYS from a dynamic amount and unit
   * @param startDate the start date, if none is presented then current date is used
   * @param endDate the end date
   */
  getDifferenceInDays(startDate: string, endDate: string): number {
    return dayjs(startDate).diff(endDate, 'days');
  }

  /**
   * check if a date is after another
   * @param date1 first dayjs date or string
   * @param date2 second dayjs date or string
   * @param format the date strings format (default is 'YYYY-MM-DDTHH:mm:ss.SSS'), use null if your date string is a ISO/Date object default timestamp
   * @returns boolean of wheter date 1 is after date 2
   */
  isDate1AfterDate2(
    date1: string | dayjs.Dayjs,
    date2: string | dayjs.Dayjs,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS',
  ): boolean {
    if (!!format) {
      return dayjs(date1, format).isAfter(dayjs(date2, format));
    }
    return dayjs(date1).isAfter(dayjs(date2));
  }

  /**
   * get first day of a date's month
   * @param date date string or dayjs
   * @param format the date string format (default is 'YYYY-MM-DDTHH:mm:ss.SSS')
   * @returns first day of the inserted date month
   */
  getStartOfCurrentMonth(
    date: string | dayjs.Dayjs,
    format = 'YYYY-MM-DDTHH:mm:ss.SSS',
  ): dayjs.Dayjs | string {
    return dayjs(date).startOf('month').format(format);
  }

  /**
   * Check if parameter date is today
   * @param date date string
   * @returns boolean
   */
  isToday(date: string): boolean {
    return dayjs(date).isToday();
  }

  /**
   * Returns the last day of month
   */
  getLastDayOfMonth() {
    return dayjs().endOf('month').format('DD/MM');
  }

  /**
   * formats difference in number of days
   * from today to natural language
   * @param days number of days
   * @returns days formatted to text
   */
  readableDaysFromNow(days: number) {
    const targetDay = dayjs().add(days, 'day');
    return targetDay.fromNow();
  }

  /**
   * formats difference betweeen dates to natural language
   * @param date1 base date to compare. Today is default
   * @param date2 second date
   * @returns days formatted to text
   */
  readableDifferenceInDates(date1 = dayjs(), date2: string | dayjs.Dayjs) {
    return dayjs(date2).from(date1);
  }

  /**
   * Returns the end of the date's day
   */
  getEndOfDay(date: string | dayjs.Dayjs) {
    return dayjs(date).endOf('day');
  }

  /**
   * Returns the start of the date's day
   */
  getStartOfDay(date: string | dayjs.Dayjs) {
    return dayjs(date).startOf('day');
  }
}
