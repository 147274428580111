import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// SERVICES
import { AuthGuard } from './guards/auth.guard';
import { ConfirmService } from './services/app-confirm/confirm.service';
import { RoutePartsService } from './services/route-parts.service';

import { FormlyModule } from '@ngx-formly/core';
import { FileUploadComponent } from '../form/components/file-upload/file-upload.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { RouterModule } from '@angular/router';
import { OrganizationGuard } from './guards/organization.guard';
import { PatientStatusGuard } from './guards/patient.status.guard';
import { SummaryStatusGuard } from './guards/summary.status.guard';
import { LoginGuard } from './guards/login-guard.service';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { LogoutGuard } from './guards/logout.guard';
import { MetabaseStatsComponent } from './components/metabase-stats/metabase-stats.component';
import { SharedMaterialModule } from './shared-material.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { OrganizationInternalGuard } from './guards/organization-internal.guard';
import { OrganizationListGuard } from './guards/organization-list.guard';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { CycleHasIncentiveGuard } from './guards/cycle-hasIncentive.guard';
import { SharedDirectivesModule } from './directives/shared.directives.module';
import { RedirectLoginGuard } from './guards/redirect-login.guard';
import { StateModule } from '../state/state.module';
import { MarTableComponent } from './components/table/mar-table.component';

@NgModule({
  declarations: [
    MetabaseStatsComponent,
    FileUploadComponent
  ],
  imports: [
    MarTableComponent,
    SharedDirectivesModule,
    SharedComponentsModule,
    SharedPipesModule,
    RouterModule,
    NgxFileDropModule,
    CommonModule,
    FormlyModule,
    SharedMaterialModule,
  ],
  providers: [
    RedirectLoginGuard,
    CycleHasIncentiveGuard,
    CanDeactivateGuard,
    OrganizationListGuard,
    OrganizationInternalGuard,
    LogoutGuard,
    RoutePartsService,
    AuthGuard,
    ConfirmService,
    LoginGuard,
    OrganizationGuard,
    PatientStatusGuard,
    SummaryStatusGuard
  ],
  exports: [
    MarTableComponent,
    SharedDirectivesModule,
    SharedComponentsModule,
    MetabaseStatsComponent,
    SharedPipesModule,
    FileUploadComponent,
    FormlyModule,
  ],
})
export class SharedModule {
}
