import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const worker = new Worker(new URL('./data-worker.ts', import.meta.url));

@Injectable({
  providedIn: 'root',
})
export class DataWorkerService {
  private dbMessages: Subject<DbMessages> = new Subject<DbMessages>();


  constructor() {
  }

  callback$(): Observable<DbMessages> {
    return this.dbMessages.asObservable();
  }

  saveItemsToWorker(items: any[], storeId: number) {
    worker.postMessage({ type: 'save', data: items, storeId });
    worker.onmessage = (event) => {
      if (event.data.type === 'saved') {
        this.dbMessages.next({
          message: 'Itens salvos com sucesso!',
          items: event.data.items,
        });
      }
    };
  }


  fetchItemsFromWorker(storeId: number) {
    worker.postMessage({ type: 'get', storeId });
    worker.onmessage = (event) => {
      if (event.data.type === 'fetched') {
        this.dbMessages.next({
          message: 'Itens recuerados',
          items: event.data.items,
        });
      }
    };
  }

  patchItemInWorker(id: number, updates: Partial<any>, storeId: number) {
    worker.postMessage({ type: 'patch', data: { id, updates }, storeId });
    worker.onmessage = (event) => {
      if (event.data.type === 'patched') {
        this.dbMessages.next({
          message: `Item com o ID ${ id } foi salvo com sucesso!`,
        });
        console.log(`Item with ID ${ id } patched successfully`);
      }
    };
  }

  deleteDatabase() {
    worker.postMessage({ type: 'deleteDatabase' });
    worker.onmessage = (event) => {
      if (event.data.type === 'deletedDatabase' && event.data.success) {
        console.log('Database deleted successfully.');
      } else if (event.data.type === 'error') {
        console.error('Error:', event.data.error);
      }
    };
  }

  deleteMultipleIdsDatabase(ids: number[], storeId: number) {
    worker.postMessage({ type: 'deleteMultiple', data: { ids }, storeId });
    worker.onmessage = (event) => {
      if (event.data.type === 'deletedMultiple' && event.data.success) {
        this.dbMessages.next({
          message: 'Itens deletados com sucesso!',
          items: event.data.items,
        });
      } else if (event.data.type === 'error') {
        console.error('Error:', event.data.error);
      }
    };
  }

}

interface DbMessages {
  message?: string;
  items?: any[];
}
