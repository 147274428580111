import { Pipe, PipeTransform } from '@angular/core';
import { UtilsService } from '../services/utils.service';
import { Organization } from '../../state/models/organization';

@Pipe({
  name: 'document',
})
export class DocumentPipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: string): string {
    return this.utilsService.maskCpfCnpj(value);
  }
}


@Pipe({
  name: 'documentByType',
})
export class DocumentByTypePipe implements PipeTransform {
  constructor(private utilsService: UtilsService) {
  }

  transform(value: Organization): string {
    if (!value) {
      return '';
    }
    return this.utilsService.formatDocument(value);
  }
}
