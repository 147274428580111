import { Injectable } from '@angular/core';
import { Menu, MenuActions } from './menu-layout.service';
import { OrganizationService } from './organization.service';
import { Organization } from '../../state/models/organization';
import { FeatureFlagService } from './feature-flag.service';
import { FeatureFlagSearchNames } from '../../state/models/feature-flag';

@Injectable({
  providedIn: 'root',
})
export class MenuActionsService {
  private organization: Organization;
  private params: any = {};

  constructor(
    private featureFlagService: FeatureFlagService,
    private organizationService: OrganizationService
  ) {
  }

  setActions(menu: Menu[], params: any): Menu[] {
    this.params = params;
    this.organization = this.organizationService.getOrganization();

    const actionOrder: MenuActions[] = [
      'FF_PAINEL_ORGANIZATION_REPORTS_61',
      'CHECK_ONLY_ONE_ORGANIZATION',
      'CHECK_CYCLE_INCENTIVE',
      'ORGANIZATION_ID_REQUIRED',
      'MIN_SUBSCRIPTION_30',
      'MIN_SUBSCRIPTIONS_30_OR_NO_CYCLE_INCENTIVE',
      'ORGANIZATION_ID_REPLACE',
      'ACTIVE',
    ];

    const updatedMenu: Menu[] = menu
      .filter(m => m?.acl?.includes(this.organization.type) || !m?.actions || !m?.acl)
      .map(m => {
        const newMenu = { ...m, ...menu.filter(m => !m?.actions) };

        if (!newMenu.actions) return { ...newMenu, showPath: true };
        actionOrder.forEach(action => {
          if (newMenu.actions.includes(action)) {
            switch (action) {
              case 'CHECK_ONLY_ONE_ORGANIZATION':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkOnlyOneOrganization();
                break;
              case 'CHECK_CYCLE_INCENTIVE':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkCycleIncentive();
                break;
              case 'ORGANIZATION_ID_REQUIRED':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkIsIdRequired();
                break;
              case 'FF_PAINEL_ORGANIZATION_REPORTS_61':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkFeatureFlagName(['PAINEL_ORGANIZATION_REPORTS_61']);
                break;
              case 'MIN_SUBSCRIPTION_30':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkMinSubscription30();
                break;
              case 'MIN_SUBSCRIPTIONS_30_OR_NO_CYCLE_INCENTIVE':
                newMenu.toRemove = newMenu?.toRemove === true ? newMenu.toRemove : this.checkMinSubscription30OrNoCycle(newMenu);
                break;
              case 'ORGANIZATION_ID_REPLACE':
                newMenu.path = this.searchIntoPath(newMenu.path);
                break;
              case 'ACTIVE':
                newMenu.active = this.checkIsUrlActive(newMenu);
                break;
            }
          }
        });
        return { ...newMenu, showPath: true };
      });

    return updatedMenu.filter(m => !m.toRemove);
  }


  private checkMinSubscription30(): boolean {
    const { activeSubscriptions } = this.organization;
    return !(activeSubscriptions > -1 && activeSubscriptions >= 30);
  }

  private checkMinSubscription30OrNoCycle(menu: Menu): boolean {
    const { activeSubscriptions } = this.organization;
    return activeSubscriptions > -1 &&
      !(activeSubscriptions >= 30 || !this.organizationService.cycle?.hasIncentive);
  }

  private checkIsIdRequired(): boolean {
    return !this.params['organizationId'];
  }

  private checkIsUrlActive(menu: Menu): boolean {
    return menu.path === location.pathname;
  }

  private checkCycleIncentive(): boolean {
    return !this.organizationService.cycle?.hasIncentive;
  }

  private checkOnlyOneOrganization(): boolean {
    return !this.organizationService.hasManyOrgs && !this.organizationService.hasAdmin;
  }

  private checkFeatureFlagName(featureFlagNames: FeatureFlagSearchNames[]): boolean {
    return !this.featureFlagService.hasFlagEnable(featureFlagNames);
  }

  private searchIntoPath(path: string): string {
    if (!this.params) return path;

    return Object.keys(this.params).reduce((newPath, key) => {
      return newPath.replace(new RegExp(key, 'g'), this.params[key]);
    }, path);
  }
}
