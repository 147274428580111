import { Injectable } from '@angular/core';
import { FeatureFlagSearch, FeatureFlagSearchNames } from '../../state/models/feature-flag';
import { FeatureFlagEntityService } from '../../state/entity-services/feature-flag-entity.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { JwtAuthService } from './auth/jwt-auth.service';
import { Person } from '../../state/models/person';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {
  user: Person = this.jwtAuthService.getUser();
  featureFlagSearchNamesEnabled$: BehaviorSubject<FeatureFlagSearchNames[]> = new BehaviorSubject([]);
  featureFlagSearchNamesDisabled$: BehaviorSubject<FeatureFlagSearchNames[]> = new BehaviorSubject([]);
  featureFlagSearchNamesEnabled: FeatureFlagSearchNames[] = [];
  featureFlagSearchNamesDisabled: FeatureFlagSearchNames[] = [];

  constructor(
    private jwtAuthService: JwtAuthService,
    private featureFlagEntityService: FeatureFlagEntityService) {
  }

  hasFlagEnable(permissionsNames: FeatureFlagSearchNames[]) {
    return this.featureFlagSearchNamesEnabled.some(ffName => {
      return permissionsNames.includes(ffName);
    });
  }

  setFeatureFlag(featureFlagSearch: FeatureFlagSearch) {
    this.featureFlagSearchNamesEnabled$.next(featureFlagSearch.enabledNames);
    this.featureFlagSearchNamesDisabled$.next(featureFlagSearch.disabledNames);
    this.featureFlagSearchNamesEnabled = featureFlagSearch.enabledNames;
    this.featureFlagSearchNamesDisabled = featureFlagSearch.disabledNames;
  }

  getFeatureFlagSearchesByOrgId(orgId: number): Observable<FeatureFlagSearch> {
    const queryParams = {
      names: ['PAINEL_PERSON_EXPORTS_SUBSCRIPTION', 'PAINEL_PERSON', 'PAINEL_ORGANIZATION_REPORTS_61'] as FeatureFlagSearchNames[],
      organization: {
        id: orgId
      },
      person: {
        id: this.user?.id
      },
    };
    return this.featureFlagEntityService.getFeatureFlagSearches(queryParams).pipe(
      tap((response) => {
        this.setFeatureFlag(response);
      }),
    );
  }

}
